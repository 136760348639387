<app-headerhome class="header-sec"></app-headerhome>

<!-- ================================
    START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area gradient-bg-gray before-none">
    <div class="breadcrumb-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-22">
                    <div class="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                        <div class="section-heading text-left">
                            <h2 class="sec__title">Customer Feedback Form for Bus Passengers</h2>
                            <p class="sec__desc font-weight-regular pb-2">Easycoach</p>
                        </div>
                    </div><!-- end breadcrumb-content -->
                </div><!-- end col-lg-22 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </div><!-- end breadcrumb-wrap -->
    <div class="bread-svg-box">
        <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
    END BREADCRUMB AREA
================================= -->

<!-- ================================
    START PRIVACY Policy AREA
================================= -->
<section class="job-details-area section--padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-20">
                <div class="form-box form-title-wrap">
                    <p class="sec__desc font-size-16 pb-3">Thank you for choosing Easy Coach! We are committed to providing our passengers
                        with a safe, reliable, and comfortable journey. Your feedback is crucial to achieving
                        these goals. Please take a moment to fill out this form and let us know how we can
                        improve.</p>
                        <h3 class="title">Personal Information (Optional)</h3>
                        <form method="post" [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" >
                        <div class="form-content ">
                            <div class="contact-form-action">
                                <div class="row">
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Your Name</label>
                                            <div class="form-group">
                                                <span class="la la-user form-icon"></span>
                                                <input class="form-control" type="text" name="name" formControlName="name" placeholder="Your name">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Your Email</label>
                                            <div class="form-group">
                                                <span class="la la-envelope-o form-icon"></span>
                                                <input class="form-control" type="email" formControlName="email" name="email" placeholder="Email address">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Contact Number: </label>
                                            <div class="form-group">
                                                <span class="la la-envelope-o form-icon"></span>
                                                <input class="form-control" type="text" formControlName="contact_number" name="contact_number" placeholder="Contact Number">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                </div>    
                            </div><!-- end contact-form-action -->
                        </div><!-- end contact-form-action -->
                        <h3 class="title">Trip Details</h3>
                        <div class="form-content ">
                            <div class="contact-form-action">
                                <div class="row">
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Route Number:</label>
                                            <div class="form-group">
                                                <span class="la la-user form-icon"></span>
                                                <input class="form-control" type="text" formControlName="route_number" name="route_number" placeholder="Your name">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Date of Journey:</label>
                                            <div class="form-group">
                                                <span class="la la-envelope-o form-icon"></span>
                                                <input class="form-control" type="email" formControlName="travel_date" name="travel_date" placeholder="Date of Journey">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                    <div class="col-lg-6 responsive-column">
                                        <div class="input-box">
                                            <label class="label-text">Time of Journey:</label>
                                            <div class="form-group">
                                                <span class="la la-envelope-o form-icon"></span>
                                                <input class="form-control" type="text" formControlName="travel_time" name="travel_time" placeholder="Time of Journey">
                                            </div>
                                        </div>
                                    </div><!-- end col-lg-6 -->
                                </div>
                            </div><!-- end form-content -->
                        </div>
                        <h3 class="title">Service Rating</h3>
                        <p class="sec__desc font-size-16 pb-3">Please rate the following aspects of our service on a scale from 1 to 5 (1 being
                            extremely dissatisfied and 5 being extremely satisfied):</p>

                            <div class="form-content ">
                                <div class="contact-form-action">
                                    <p class="_pb-10"><b>Punctuality</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="punctuality" name="punctuality" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="punctuality" name="punctuality" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="punctuality" name="punctuality" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="punctuality" name="punctuality" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="punctuality" name="punctuality" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                    <p class="_pb-10"><b>Cleanliness</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="cleanliness" name="cleanliness" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="cleanliness" name="cleanliness" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="cleanliness" name="cleanliness" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="cleanliness" name="cleanliness" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="cleanliness" name="cleanliness" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                    <p class="_pb-10"><b>Driver's Conduct</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="driver_conduct" name="driver_conduct" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="driver_conduct" name="driver_conduct" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="driver_conduct" name="driver_conduct" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="driver_conduct" name="driver_conduct" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="driver_conduct" name="driver_conduct" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                    <p class="_pb-10"><b>Seat Comfort</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="seat_comfort" name="seat_comfort" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="seat_comfort" name="seat_comfort" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="seat_comfort" name="seat_comfort" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="seat_comfort" name="seat_comfort" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="seat_comfort" name="seat_comfort" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                    <p class="_pb-10"><b>Bus Condition</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="bus_condition" name="bus_condition" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="bus_condition" name="bus_condition" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="bus_condition" name="bus_condition" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="bus_condition" name="bus_condition" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="bus_condition" name="bus_condition" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                    <p class="_pb-10"><b>Safety Measures</b></p>
                                    <div class="row">
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">1 <input class="form-control" type="radio" formControlName="safety_measures" name="safety_measures" value="1">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">2 <input class="form-control" type="radio" formControlName="safety_measures" name="safety_measures" value="2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">3 <input class="form-control" type="radio" formControlName="safety_measures" name="safety_measures" value="3">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">4 <input class="form-control" type="radio" formControlName="safety_measures" name="safety_measures" value="4">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                        <div class="col-lg-2 responsive-column">
                                            <label class="custom-radio">5 <input class="form-control" type="radio" formControlName="safety_measures" name="safety_measures" value="5">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div><!-- end col-lg-2 -->
                                    </div>
                                </div><!-- end form-content -->
                            </div>
                                <h3 class="title">Additional Comments</h3>
                                <div class="form-content ">
                                    <div class="contact-form-action">
                                        <div class="row">
                                            <div class="col-lg-8 responsive-column">
                                                <div class="input-box">
                                                    <label class="label-text">What did you like most about your experience?</label>
                                                    <div class="form-group">
                                                        <span class="la la-pencil form-icon"></span>
                                                        <textarea class="message-control form-control" formControlName="most_liked" name="most_liked" placeholder="Write message"></textarea>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-6 -->
                                            <div class="col-lg-8 responsive-column">
                                                <div class="input-box">
                                                    <label class="label-text">What did you like least about your experience?</label>
                                                    <div class="form-group">
                                                        <span class="la la-pencil form-icon"></span>
                                                        <textarea class="message-control form-control" formControlName="least_liked"name="least_liked" placeholder="Write message"></textarea>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-6 -->
                                             <div class="col-lg-8 responsive-column">
                                                <div class="input-box">
                                                    <label class="label-text">Do you have any suggestions for improvement?</label>
                                                    <div class="form-group">
                                                        <span class="la la-pencil form-icon"></span>
                                                        <textarea class="message-control form-control" formControlName="improvement" name="improvement" placeholder="Write message"></textarea>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-6 -->
                                           
                                           
                                        </div>
                                    </div><!-- end form-content -->
                                </div>
                                <h3 class="title">Contact Consent</h3>
                                <p class="sec__desc font-size-16 pb-3">Would you like to be contacted by a member of our 
                                    team to discuss your feedback in more detail?</p>
                                <div class="form-content ">
                                    <div class="contact-form-action">
                                        <div class="row">
                                            <div class="col-lg-2 responsive-column">
                                                <label class="custom-radio">Yes 
                                                    <input class="form-control" type="radio" formControlName="concent" name="concent" value="Yes">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div><!-- end col-lg-6 -->
                                            <div class="col-lg-2 responsive-column">
                                                <label class="custom-radio" >No <input class="form-control" type="radio" formControlName="concent" name="concent" value="No">
                                                  <span class="checkmark"></span>
                                                </label>
                                            </div><!-- end col-lg-6 -->
                                            
                                        </div>    
                                    </div><!-- end contact-form-action -->
                                </div><!-- end contact-form-action -->    
                                <div class="row">
                                <div class="col-lg-10 _pb-10 _pt-20">
                                    <div class="btn-box">
                                        <button type="submit" class="theme-btn pull-right">Submit</button>
                                    </div>
                                </div><!-- end col-lg-12 -->   
                               </div>    

                    </form>
                    <p class="sec__desc font-size-16 pb-3">Thank you for taking the time to fill out this form. Your input is invaluable to us.</p>
                    <div class="row">
                        <div class="col-lg-5"><a href="https://easycoachkenya.com/">www.easycoachkenya.com</a></div>
                        <div class="col-lg-5"><span class="phones-wrapper"><a href="mailto:info@mashpoa.com">info@mashpoa.com</a></span><br>
                        </div>
                    </div>
                     <p class="sec__desc font-size-16 pb-3 _pt-20"> Your privacy is important to us. All personal information will be kept confidential and
                    will only be used to improve our services.</p>
                    
              

                </div><!-- end form-box -->
            </div><!-- end col-lg-8 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end PRIVACY-POLICY-area -->
<!-- ================================
    END PRIVACY-POLICY AREA
================================= -->
<app-footer></app-footer>