import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  public successValue = new BehaviorSubject<string>('');
  successValueChange = this.successValue.asObservable();



  constructor() { }

  changeSuccessMessage(message: string) {
    this.successValue.next(message)
  }

  changeMessage(message: string) {
    localStorage.setItem('search-mashpoa', message);
    this.messageSource.next(message)
  }
}
