import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class StateServiceService {

  private isRoundTripSource = new BehaviorSubject<boolean>(false);
  isRoundTrip$ = this.isRoundTripSource.asObservable();

  setRoundTrip(value: boolean): void {
    this.isRoundTripSource.next(value);
  }
}
