import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromCity from '../../store/city-selection/city-selection.reducer';
import { AppState } from '../../store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { selectTravelDate,selectReturnDate } from '../../store/city-selection/city-selection.reducer';
import { formatDate } from '@angular/common';
import { Base64 } from 'js-base64';
import {HomeService,DataService, StateServiceService } from '../../services/index'


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  reactiveForm: UntypedFormGroup;
  leavingFrom$ = this.store.pipe(select((state: AppState) => state.city.leavingFrom));
  goingTo$ = this.store.pipe(select((state: AppState) => state.city.goingTo));
  travelDate$ =this.store.pipe(select((state: AppState) => state.city.travelDate));
  returnDate$ =this.store.pipe(select((state: AppState) => state.city.returnDate));
  minDate: Date = new Date();

  formdata={};
  submitted:boolean = false;
  isRoundTrip = false; 

  dates: { day: number, month: string, weekday: string, year: number }[] = [];

  datesReturn: { day: number, month: string, weekday: string, year: number }[] = [];

   currentDate = new Date();
   returnDate = new Date();
   daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
   monthsOfYear = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


  constructor(
    private router: Router, 
    private store: Store,   
    private _fb: UntypedFormBuilder,
    private dataService:DataService,
    private stateService: StateServiceService
    ) { 
    this.minDate.setHours(0, 0, 0, 0);
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      travelDate:[{
        day: this.currentDate.getDate(),
        month: this.monthsOfYear[this.currentDate.getMonth()],
        weekday: this.daysOfWeek[this.currentDate.getDay()],
        year: this.currentDate.getFullYear()
      }, Validators.required],
      returndate: [{
        day: this.returnDate.getDate() + 1,
        month: this.monthsOfYear[this.returnDate.getMonth()],
        weekday: this.daysOfWeek[this.returnDate.getDay()],
        year: this.currentDate.getFullYear()
      }, Validators.required]  // Add this line
      // returndate:['']
    });
  }
  ngOnInit(): void {
    this.leavingFrom$.subscribe(city => {
      if (city) {
        this.reactiveForm.get('pickup').setValue(city);
      }
  });
  this.goingTo$.subscribe(city => {
    if (city) {
      this.reactiveForm.get('dropoff').setValue(city);
    }
  });

  this.travelDate$.subscribe(date => {

    if (date) {

      this.reactiveForm.get('travelDate').setValue({
        day: date.getDate(),
        month: this.monthsOfYear[date.getMonth()],
        weekday: this.daysOfWeek[date.getDay()],
        year: date.getFullYear()
      });

        this.currentDate = date;
        this.changeDateRange(date);
      
    }
  });

  this.returnDate$.subscribe(date => {
    if (date) {
      this.reactiveForm.get('returndate').setValue({
        day: date.getDate(),
        month: this.monthsOfYear[date.getMonth()],
        weekday: this.daysOfWeek[date.getDay()],
        year: date.getFullYear()
      });
      this.returnDate = date;
      // this.isRoundTrip = true
      this.changeDateReturn(date);
    }

  });

  this.stateService.isRoundTrip$.subscribe(value => {
    this.isRoundTrip = value;
  });

  this.changeDateRange(this.currentDate)
  this.changeDateReturn(this.returnDate)

  }

  setTripType(tripType: string) {
    if(tripType === 'roundtrip'){
      this.stateService.setRoundTrip(true);
    }
    this.isRoundTrip = tripType === 'roundtrip';

}

 
  changeDateRange(activeDate: Date) {
    this.dates = [];
    
    
    const diffInDays = (date1: Date, date2: Date) => {
      return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
    };
  
    const daysDifference = diffInDays(activeDate, this.minDate);
  
    if (daysDifference === 0) {
      // Active date is today, make it the first item in the list
      for (let i = 0; i < 5; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addDate(nextDate);
       
      }
    } else if (daysDifference >= 2) {
      // Active date is two days or more after today, set it in the center
      for (let i = -2; i < 3; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addDate(nextDate);
       
      }
    } else {
      // For other scenarios (e.g., active date is tomorrow), you can choose a default behavior or handle differently
      for (let i = -1; i < 4; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addDate(nextDate);
       
      }
    }
  }

  changeDateReturn(activeDate: Date){
    this.datesReturn = [];
    
    const diffInDays = (date1: Date, date2: Date) => {
      return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
    };
  
    const daysDifference = diffInDays(activeDate, this.minDate);
  
    if (daysDifference === 0) {
      // Active date is today, make it the first item in the list
      for (let i = 0; i < 5; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addReturnDate(nextDate);
      }
    } else if (daysDifference >= 2) {
      // Active date is two days or more after today, set it in the center
      for (let i = -2; i < 3; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addReturnDate(nextDate);
      }
    } else {
      // For other scenarios (e.g., active date is tomorrow), you can choose a default behavior or handle differently
      for (let i = -1; i < 4; i++) {
        const nextDate = new Date(activeDate);
        nextDate.setDate(activeDate.getDate() + i);
        this.addReturnDate(nextDate);
      }
    }
  }

  addDate(date: Date) {
    
    if (date.getMonth() === this.currentDate.getMonth()) {
      this.dates.push({
        day: date.getDate(),
        month: this.monthsOfYear[date.getMonth()],
        weekday: this.daysOfWeek[date.getDay()],
        year: date.getFullYear()
      });
      
    }else{
     
    }
 
  }

  addReturnDate(date: Date) {
    if (date.getMonth() === this.returnDate.getMonth()) {
      this.datesReturn.push({
        day: date.getDate(),
        month: this.monthsOfYear[date.getMonth()],
        weekday: this.daysOfWeek[date.getDay()],
        year: date.getFullYear()
      });
    }
  }



  setReturnDate(date){
  
    
    // this.reactiveForm.get('returndate').setValue(date);
    // Update the displayed dates based on the clicked date
    const clickedDate = new Date(this.returnDate);
    clickedDate.setDate(date.day);
    if (clickedDate.getMonth() === this.currentDate.getMonth()) {
      if (clickedDate > this.currentDate) {

      }else{

      }

    }else{

    }
    if (clickedDate >= this.minDate) {
      this.reactiveForm.get('returndate').setValue(date);
      this.changeDateReturn(clickedDate);
    } else {

    }
  }
  

  setTravelDate(date) {
    // this.reactiveForm.get('travelDate').setValue(date);
    // Update the displayed dates based on the clicked date

    const clickedDate = new Date(this.currentDate);
    clickedDate.setDate(date.day);
 
 
    if (clickedDate >= this.minDate) {

      this.reactiveForm.get('travelDate').setValue(date);
      this.changeDateRange(clickedDate);
      this.currentDate = clickedDate;
    } else {

    }
  
  }

  openCitySelection(field: string) {
    this.router.navigate(['/select-city'], { queryParams: { field: field} });
  }

  navigateToFullCalendar(type) {
    if(type === 'travel'){
      this.router.navigate(['/full-page-calendar/travel']);
    }else{
      this.stateService.setRoundTrip(true);
      this.router.navigate(['/full-page-calendar/return']);
    }

 // Update with your route
  }

  switchValues() {
    const currentPickup = this.reactiveForm.get('pickup').value;
    const currentDropoff = this.reactiveForm.get('dropoff').value;
  
    // Switch the values
    this.reactiveForm.get('pickup').setValue(currentDropoff);
    this.reactiveForm.get('dropoff').setValue(currentPickup);
  }
  

  submitMobileResForm() {
    this.submitted = true;
     let value = this.reactiveForm.value;

      // Convert travelDate object back to a Date
      const monthIndex = this.monthsOfYear.indexOf(value.travelDate.month);
      const travelDateObject = new Date(value.travelDate.year, monthIndex, value.travelDate.day);
      
      var traveldate = formatDate(travelDateObject, "yyyy-MM-dd", "en-KE");
      value.travelDate = traveldate;

      var returndate = '';
      if (this.isRoundTrip && value.returndate) {
        const monthIndex = this.monthsOfYear.indexOf(value.returndate.month);
        const travelDateObject = new Date(value.travelDate.year, monthIndex, value.returndate.day);
        returndate = formatDate(travelDateObject, "yyyy-MM-dd", "en-KE");
        value.returndate = returndate;
    }
    // if(this.returning){
    //   var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    //    value.returndate=returndate;
    // }

    this.formdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : false
    }


    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }
}
