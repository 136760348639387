
<div class="header-class">
    <h2 class="h2-class">Choose your city</h2>
</div>

<div class="search-container">
    <svg class="search-icon" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="margin-top: 4px; font-size: 2rem; color: grey;"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
    <input [formControl]="searchControl" type="text" placeholder="Search for city">
</div>

<div class="popular-stations-header">
    Popular stations
</div>

<ul *ngFor="let city of filteredCities; let i = index"  class="stations-list">
    <li (click)="selectCity(city)">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 10 12" fill="currentColor"><path data-name="Path 1660" d="M69,301a4.789,4.789,0,0,0-5,4.532c0,3.552,4.53,7.218,4.723,7.373a.453.453,0,0,0,.554,0c.193-.154,4.723-3.821,4.723-7.373A4.789,4.789,0,0,0,69,301Zm0,7a2.514,2.514,0,1,1,2.778-2.5A2.652,2.652,0,0,1,69,308Z" transform="translate(-64 -301)"></path></svg>
         <span class="_ml-10">{{city}}</span>
    </li>
    <!-- Repeat list items for other cities -->
   
</ul>
