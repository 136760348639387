import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTime'
})
export class ConvertTimePipe implements PipeTransform {

  transform(time: string): string {
    let hours = parseInt(time.slice(0, 2));
    let minutes = time.slice(3, 5);
    let ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12;

    let dayOrNight = hours >= 6 && hours < 18 ? "(day)" : "(night)";
    let strTime = hours + ':' + minutes + ' ' + ampm + ' ' + dayOrNight;
    return strTime;
  }

}
