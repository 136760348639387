<app-headerhome></app-headerhome>
<div class="progress-cntr-wrpr">
  <div class="progress-cntr">
      <div class="progress-tracker-cntr transparent">
          <div class="progress-tracker">
              <div class="progress-item first ">
                  <div class="item-wrapper passed">
                      <div class="progess-item-label">Booking</div>
                  </div>
              </div>
              <div class="progress-item passed">
                  <div class="item-wrapper">
                      <div class="progess-item-label">Payment</div>
                  </div>
              </div>
              <div class="progress-item passed">
                  <div class="item-wrapper">
                      <div class="progess-item-label">Ticket</div>
                  </div>
              </div>
              <div class="progress-tracker-completed" style="width: 0%;"></div>
          </div>
      </div>
  </div>
</div>
<div class="theme-page-section  theme-page-section-gray searchFont">
        <div class="container">
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <i class="fa fa-check-circle fa-3x theme-payment-success-header-icon"></i>
                  <h1 class="theme-payment-success-title">Booking Successful</h1>
                  <p class="theme-payment-success-subtitle">A confirmation SMS has been sent to provided mobile number.Thank You.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<app-footer></app-footer>
