import { Component } from '@angular/core';

@Component({
  selector: 'app-cities-to',
  templateUrl: './cities-to.component.html',
  styleUrls: ['./cities-to.component.css']
})
export class CitiesToComponent {

}
