import { createAction, props } from '@ngrx/store';

export const setLeavingFromCity = createAction(
  '[City] Set Leaving From City',
  props<{ city: string }>()
);

export const setGoingToCity = createAction(
  '[City] Set Going To City',
  props<{ city: string }>()
);

export const setTravelDate = createAction(
  '[City Selection] Set Travel Date',
  props<{ date: Date }>()
);

export const setReturnDate = createAction(
  '[City Selection] Set Return Date',
  props<{ date: Date }>()
);