import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CityActions from '../../store/city-selection/city-selection.actions';
import { FormControl } from '@angular/forms';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-cities-from',
  templateUrl: './cities-from.component.html',
  styleUrls: ['./cities-from.component.css']
})
export class CitiesFromComponent implements OnInit  {
  searchControl = new FormControl('');
  allCities: string[] = []; // Your list of cities
  filteredCities: string[] = [];
  
  topcities: string[] = ['Nairobi', 'Mombasa', 'Kisumu','Busia','Kampala','Nakuru','Kigali']; // Add your cities
  selectedField: string;

  constructor(private router: Router, private route: ActivatedRoute,private store: Store) { }
  destinations: string[] = [];
  ngOnInit() {

    const citiesEncoded = localStorage.getItem('mashpoa-cities');
    if (citiesEncoded) {
      const citiesString = Base64.decode(citiesEncoded);
      const citiesArray = JSON.parse(citiesString); // Assuming this is an array of cities

      this.allCities = [...new Set([...this.topcities, ...citiesArray])];
      this.filteredCities = [...this.allCities]; // Update filteredCities with allCities data
    }

    this.route.queryParams.subscribe(params => {
      this.selectedField = params['field'];
  
    });
    this.searchControl.valueChanges.subscribe(searchValue => {
      this.filteredCities = this.filterCities(searchValue);
    });


  }

  filterCities(searchValue: string): string[] {
    return this.allCities.filter(city => city.toLowerCase().includes(searchValue.toLowerCase()));
  }

  selectCity(city: string) {

    if (this.selectedField === 'from') {
      console.log('Dispatching setLeavingFromCity action');
      this.store.dispatch(CityActions.setLeavingFromCity({ city }));
    } else if (this.selectedField === 'to') {
      console.log('Dispatching setGoingToCity action');
      this.store.dispatch(CityActions.setGoingToCity({ city }));
    }
    this.router.navigate(['/']); // navigate back to the form
  }
}
