<app-headerhome></app-headerhome>
<div class="progress-cntr-wrpr">
        <div class="progress-cntr">
            <div class="progress-tracker-cntr transparent">
                <div class="progress-tracker">
                    <div class="progress-item passed ">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Booking  </div>
                        </div>
                    </div>
                    <div class="progress-item passed">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Payment</div>
                        </div>
                    </div>
                    <div class="progress-item last passed">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Ticket</div>
                        </div>
                    </div>
                    <div class="progress-tracker-completed" style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
<div class="theme-page-section  theme-page-section-gray">
        <div class="container">
          <div class="row">
            <div class="col-md-5 offset-md-3">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
                  <h1 class="theme-payment-success-title">Booking Successful</h1>
                  <p class="theme-payment-success-subtitle">Thank You. Your Booking Order is Confirmed Now.
                        A confirmation SMS has been sent to your provided phone number.</p>
                        <!-- <p><qrcode [qrdata]="'{{ticketDetails.booking_id}}'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                        </p> -->
                </div>


                <div class="theme-payment-success-box">
                  <ul class="theme-payment-success-summary">
                    <li>Booking Reference
                      <span>{{condfirmData.data.booking_id}}</span>
                    </li>
                    <li>Travel Date
                      <span>{{condfirmData.depature_date}}</span>
                    </li>
                    <li>Pickup
                      <span>{{bookingdetails.pickup_location}}</span>
                    </li>
                    <li>Dropoff
                      <span>{{bookingdetails.dropoff_location}}</span>
                    </li>

                    <li class="passenger-details">
                      Passenger(s) <i class="text-light fa fa-male"></i>
                      <br>
                            <div  class="passenger-info" *ngFor="let passenger of condfirmData.passengers">

                            <p><b>{{passenger.full_name}}</b>
                              <span *ngIf="!condfirmData.is_shuttle"><small class="p-left"> Seat: </small>
                              <b>{{passenger.seat_number}}</b></span><a href="#"
                              class=""
                              data-toggle="modal"
                              data-target="#QRopupForm{{passenger.pnr_number}}"><small> View QR code</small></a>
                            </p>
                              </div>
                      </li>
                  </ul>
                  <p class="pull-right"><a href="/">Go Back Home</a></p>
                  <div class="modal-popup">
                    <div
                      class="modal fade"
                      id="QRopupForm{{rawTicketDetails.transaction_id}}"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button
                              type="button"
                              class="close"
                              id="closeLogin"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true" class="la la-close"></span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="contact-form-action">
                              
                                  <div
                                    class="d-flex align-items-center justify-content-between"
                                  >
                                    <p class="forgot-password">
                                      <qrcode [qrdata]="'{{ticketDetails.booking_id}}'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                                    </p>
                                  </div>
                                </div>
                            </div>
                            <!-- end contact-form-action -->
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


<app-footer></app-footer>
