import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css']
})
export class CustomerFeedbackComponent implements OnInit {
  feedbackForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private http: HttpClient) { 
    this.feedbackForm = this.fb.group({
      name: [''],
      email: ['', [Validators.email]],
      contact_number: [''],
      route_number: ['', Validators.required],
      travel_date: ['', Validators.required],
      travel_time: ['', Validators.required],
      punctuality: ['', Validators.required],
      cleanliness: ['', Validators.required],
      driver_conduct: ['', Validators.required],
      seat_comfort: ['', Validators.required],
      bus_condition: ['', Validators.required],
      safety_measures: ['', Validators.required],
      most_liked: ['', Validators.required],
      least_liked: ['', Validators.required],
      improvement: ['', Validators.required],
      concent: ['', Validators.required]
    });
  }

  ngOnInit() {
  
  }

      onSubmit() {
        if (this.feedbackForm.valid) {
          const formData = this.feedbackForm.value;
          this.http.post('YOUR_API_ENDPOINT_HERE', formData).subscribe(
            response => {
              console.log('Form submitted successfully', response);
              // Handle successful response
            },
            error => {
              console.error('Error submitting form', error);
              // Handle error response
            }
          );
        } else {
          console.error('Form is invalid');
          // Handle form validation errors
        }
      }
}
