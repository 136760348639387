<div class="header-class">
    <h2 class="h2-class">Select Travel Date</h2>
</div>
<div class="search-container">
    <div class="calendar-container">
        <bs-datepicker-inline 
        [minDate]="today" 
        (bsValueChange)="onDateChange($event)"
        [bsConfig]="{ returnFocusToInput: true }"
        ></bs-datepicker-inline>
    </div>
</div>
  