import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
// import { setReturnDate, setTravelDate } from 'src/app/store/city-selection/city-selection.actions';
import * as CityActions from '../../store/city-selection/city-selection.actions';
@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.css']
})
export class FullCalendarComponent {
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  today=new Date();
  @Input() dateType: 'travel' | 'return' = 'travel';

 
  constructor(
    private router: Router,
    private store: Store,
    private route: ActivatedRoute
    ) {
      this.dateType = this.route.snapshot.paramMap.get('dateType') as 'travel' | 'return';
  }

  // onDateChange(selectedDate: Date): void {
  //   console.log(selectedDate)
  //   this.store.dispatch(setTravelDate({ date: selectedDate }));
  //   this.router.navigate(['/']);
  // }

  onDateChange(selectedDate: Date): void {
    if (this.dateType === 'travel') {
        this.store.dispatch(CityActions.setTravelDate({ date: selectedDate }));
    } else if (this.dateType === 'return') {
        this.store.dispatch(CityActions.setReturnDate({ date: selectedDate }));
    }

    this.router.navigate(['/']);
}
}
