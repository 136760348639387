const marketplaceUrl= 'https://marketplace-v2.buupass.com/marketplace/';
// const marketplaceUrl= 'https://dev.marketplace-api.buupass.com/marketplace/'
  const v2P='https://pay-v2.buupass.com/payments/';
  const marketplaceUrlTrips= 'https://easycoach.buupass.com/';
  const usersApi='https://v2-user-management.buupass.com/user-management/users/';
  const usersApiLogin='https://v2-user-management.buupass.com/user-management/users/login';
  
export const environment = {
    api_key:'Pt5BLUID.ntj1S2NQMJac0IbRV1IDF3XDLb8jwPDY',
    production: false,
    getDestinations: marketplaceUrl+'buses/bms/website?request_call=aliases',
    searchBuses: marketplaceUrl+'buses/bms/website?request_call=schedules',

    // getDestinations: marketplaceUrl+'buses/cities/aliases',
    // searchBuses: marketplaceUrl+'buses',

    getSeats: marketplaceUrl + 'buses/seats',
    bookingApi: marketplaceUrl+'booking/',
    bookingApiV2: marketplaceUrl+'booking/',
    confirmApi:marketplaceUrl+'website/api_mpesa_query_status/',
    bookingCheckPayApi: marketplaceUrl + 'booking/pay',
    v2confirm: v2P+'c2b/transaction/status/',
    retrieveEmail: usersApi+'retrieve/',
    resetPassword: usersApi+'password-reset',
    codePass: usersApi + 'code-validate',
    updateCodePass: usersApi + 'password-reset/',
    signUp: usersApi+'registration',
    loginUser:usersApi+'login',
    getProfile: usersApi+'profile',
    usersApiLogin:usersApiLogin,
    getTrips: marketplaceUrl+'booking/search?search=',
    getHistory: marketplaceUrlTrips+'api/history/',
    submitCarhire: marketplaceUrl+'booking/bms-website/car-hire',
};
