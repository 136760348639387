

    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">
            <div class="col-lg-4">
              <div class="theme-footer-section theme-footer-">
                <a class="theme-footer-brand _mt-20" href="#">
                  <img src="assets/images/logo.webp" alt="Mashpoa"  title="Image Title"/>
                </a>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-3">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Company</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a [routerLink]="['/about-us']">About us</a>
                      </li>
                      <li>
                        <a [routerLink]="['/contact-us']">Contact us</a>
                      </li>
                      <li>
                        <a  [routerLink]="['/privacy-policy']">Terms & Conditions </a>
                      </li>
                      <li>
                        <a  [routerLink]="['/privacy-policy']">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
    
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Contacts</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254733623260">+254 733 623 260 / 730 889 000 Mombasa</a> </span><br>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254113835631">+254 113 835 631 Nairobi</a> </span><br>

                      </li>
                      <li>
                        <h6 class="phone-titles"> Email:</h6>
                        <span class="phones-wrapper"><a href="mailto:info@mashpoa.com">info@mashpoa.com</a></span><br>
                        
                      </li>
    
                    </ul>
                  </div>
    
                </div >
    
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Top Routes</h5>
                    <ul class="theme-footer-section-list">
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Mombasa')" >Nairobi - Mombasa</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Malindi')" >Nairobi - Malindi</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Kigali')" >Nairobi - Kigali </a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Kampala')" >Nairobi - Kampala </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-app-cta">
                  <div class="_mt-40 mobile-app-cta-inner text-center">
                      <h3 class="_ml-10 ">GET THE APP!</h3>
                      <a class="centered-img" href="https://play.google.com/store/apps/details?id=io.mashpoamarketplace">
                        <img src="assets/img/company1/app-logo-GooglePlay.png" alt="Download on play store" class="icon-app-store">
                    </a>
                    <ul class="theme-copyright-social _mt-10">
                      <li>
                        <a class="fa fa-facebook icon-color-facebook fa-2x"  href="https://web.facebook.com/masheastafrica" target="_blank"></a>
                      </li>
      
                      <li>
                        <a class="fa fa-twitter icon-color-twitter fa-2x" href="https://twitter.com/masheastafrica" target="_blank"></a>
                      </li>
           
                    </ul>
                  </div>
                </div>
    
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <div class="theme-copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="theme-copyright-text">Copyright &copy; 2023
                <a href="/">Mash Poa</a>. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>

