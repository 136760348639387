import { createReducer, createSelector, on } from '@ngrx/store';
import * as CityActions from './city-selection.actions';
import { AppState } from '..';

export const cityFeatureKey = 'city';

export interface State {
  leavingFrom: string;
  goingTo: string;
  travelDate: Date | null;
  returnDate: Date | null;
}

export const initialState: State = {
  leavingFrom: '',
  goingTo: '',
  travelDate: new Date(),
  returnDate: new Date()
};

export const reducer = createReducer(
  initialState,
  on(CityActions.setLeavingFromCity, (state, action) => {
    const newState = { ...state, leavingFrom: action.city };
    return newState;
  }),
  on(CityActions.setGoingToCity, (state, action) => {
    const newState = { ...state, goingTo: action.city };
    return newState;
  }),

  on(CityActions.setTravelDate, (state, { date }) => {
    return { ...state, travelDate: date };
  }),
  on(CityActions.setReturnDate, (state, { date }) => ({
    ...state,
    returnDate: date
  })),

);

export const selectTravelDate = createSelector(
  (state: AppState) => state.city,
  (cityState: State) => cityState.travelDate
);

export const selectReturnDate = createSelector(
  (state: AppState) => state.city,
  (cityState: State) => cityState.returnDate
);
