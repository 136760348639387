<div class="card-mobile">
  
    <div class="trip-type">
        <button [ngClass]="{'selected': !isRoundTrip}" class="trip-button" (click)="setTripType('oneway')">One way</button>
        <button [ngClass]="{'selected': isRoundTrip}" class="trip-button" (click)="setTripType('roundtrip')">Round Trip</button>
    </div>
    <form [formGroup]="reactiveForm" (ngSubmit)="submitMobileResForm()" >
        <div class="input-wrapper">
            <svg class="location-icon" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="vertical-align: middle; margin-right: 10px; font-size: 24px; width: 24px; height: 24px;"><path d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z"></path></svg>
            <input name="pickup" formControlName="pickup" [value]="leavingFrom$ | async" (click)="openCitySelection('from')" type="text" placeholder="Leaving from" class="bottom-border-input">
            <svg class="switch-icon" (click)="switchValues()" id="Group_287" data-name="Group 287" width="1em" height="1em" viewBox="0 0 36 36" class="svg-icon" style="font-size: 42px;"><path id="Path_734" data-name="Path 734" d="M30.728,5.272A18,18,0,0,0,5.272,30.728,18,18,0,0,0,30.728,5.272Zm-18.673,22.3L6.068,21.538l1.5-1.485,3.385,3.413V9.929H13.06V23.523l3.378-3.464,1.51,1.473ZM28.428,15.941,25.05,12.477V26.071H22.94V12.534l-3.385,3.413-1.5-1.485,5.987-6.037,5.893,6.043Z" transform="translate(0 0)" fill="currentColor" stroke="transparent"></path></svg>
        
        </div>
        <div class="input-wrapper">
            <svg class="pin-icon" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="vertical-align: middle; margin-right: 10px; font-size: 24px; width: 24px; height: 24px;"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>
            <input name="dropoff" formControlName="dropoff" [value]="goingTo$ | async" (click)="openCitySelection('to')" type="text" placeholder="Going To" class="bottom-border-input">
        </div>
        <div class="departure-text">Departure</div>
        <div class="date-row">
            <div class="date-item" 
                *ngFor="let date of dates" 
                [class.active]="reactiveForm.get('travelDate')?.value?.day === date.day"
                (click)="setTravelDate(date)">
            {{ date.day }}<br><span>{{ date.weekday }}</span>
            </div>
            <!-- Vertical line -->
            <div class="vertical-line"></div>
            
            <!-- Month, Year and Dropdown Icon -->
            <div class="year-dropdown" (click)="navigateToFullCalendar('travel')">
                {{ dates[0].month }}
                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="width: 30px; height: 30px; color: rgb(22, 86, 186);"><path d="M7 10l5 5 5-5z"></path></svg>
            </div>
        </div>
        <div *ngIf="isRoundTrip" class="return-trip">
            <div class="departure-text">Return</div>
            <div class="date-row">
                <div class="date-item" 
                    *ngFor="let date of datesReturn" 
                    [class.active]="reactiveForm.get('returndate')?.value?.day === date.day"
                    (click)="setReturnDate(date)">
                    {{ date.day }}<br><span>{{ date.weekday }}</span>
                </div>
                <!-- Vertical line -->
                <div class="vertical-line"></div>
                
                <!-- Month, Year and Dropdown Icon -->
                <div class="year-dropdown" (click)="navigateToFullCalendar('return')">
                    {{ datesReturn[0].month }}
                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="width: 30px; height: 30px; color: rgb(22, 86, 186);"><path d="M7 10l5 5 5-5z"></path></svg>
                </div>
            </div>

        </div>
   

        <button type="submit" class="full-width-btn">Search</button>
    </form>
</div>
